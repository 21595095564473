import apiPath from '../constants/apiPath';
import _ from 'lodash';
async function Http(path, options) {
    let BaseURL = apiPath.baseURL;
    let noAuth = ["api/admins/login"];
    if(!_.includes(noAuth, path)) {
      let token = localStorage.getItem("admin")? JSON.parse(localStorage.getItem("admin")).admin.token: '';
      options.headers["x-access-token"] = token;
    }
    let result = await fetch(BaseURL+path, options);
    if(result.status === 401) {
      localStorage.removeItem("admin");
    }
  return result;
}
export default Http;
