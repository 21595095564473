import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
import queryString from 'query-string';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class UserChangePassword extends Component {
  constructor(props) {
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      password: '',
      token: queryString.parse(this.props.location.search).token
    }


  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit = (e, formData, inputs) => {
    e.preventDefault();
    console.log(formData);
    this.userResetPassword(formData);
  }

  handleErrorSubmit = (e, formData, errorInputs) => {
    console.log(e, formData, errorInputs)
  }

  resetForm = () => {
    let formRef = this.formRef.current;
    formRef.resetValidationState(this.state.clearInputOnReset);
  }

  async userResetPassword(postData) {
    let path = apiPath.userResetPassword;

    if (!_.isEmpty(postData)) {
      this.setState({ loading: true });
      let options = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      }
      let result = await Http(path, options);
      this.setState({ loading: false });
      if (result.ok) {
        if (result.status === 200) {

          let resultData = await result.json();
          if (resultData.success) {

            this.setState({ submitFormSuccess: resultData.msg });
            this.resetForm();
          } else {
            this.setState({ submitFormError: resultData.msg });
            setTimeout(() => {
              this.setState({ submitFormError: null });
            }, 5000);
          }
        }
      } else {
        console.log(result);
        this.setState({ submitFormError: result.statusText });
        setTimeout(() => {
          this.setState({ submitFormError: null });
        }, 5000);
      }
    } else {
      console.log('No Response');
    }
  }

  responseError() {
    if (this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  render() {
    if (_.isEmpty(this.state.token)) {
      return <Redirect to='/login' />
    }

    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  {this.responseError()} {this.responseSuccess()}
                  <CardBody>
                    <ValidationForm onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}
                      ref={this.formRef}
                      immediate={this.state.immediate}
                      setFocusOnError={this.state.setFocusOnError}
                      defaultErrorMessage={{ required: "Please enter something." }} >
                      <h1>Change Password</h1>
                      <p className="text-muted">Enter your new password </p>
                      <TextInput type={"hidden"} value={this.state.token} onChange={this.handleChange} name="token" id="token" required />
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <TextInput
                          type={"password"} value={this.state.password} onChange={this.handleChange} name="password" id="password"
                          placeholder={"New Password"}
                          required
                          maxLength={10}
                          pattern="^(?=.*\d)(?=.*[a-zA-Z]).{6,10}$"
                          errorMessage={{ required: 'Please enter your new password.', pattern: "Password must be 6 to 10 characters long and should include letters and numbers." }}
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="4">
                          <Button color="primary" type={"submit"} className="px-4">Submit {this.showLoading()}</Button>
                        </Col>
                        
                      </Row>
                    </ValidationForm>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2 className="notranslate">Speedy Tum Tum</h2>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword);
                       
