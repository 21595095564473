import React, { Component } from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, LSPRegister } from './views/Pages';
import LSPLogin from './views/Pages/Login/LSPLogin';
import LSPForgotPassword from './views/Pages/Login/LSPForgotPassword';
import ForgotPassword from './views/Pages/Login/ForgotPassword';

import LSPAccountChangePassword from './views/Pages/Login/LSPAccountChangePassword';
import ChangePassword from './views/Pages/Login/ChangePassword';
import UserChangePassword from './views/Pages/Login/UserChangePassword';
import DriverChangePassword from './views/Pages/Login/DriverChangePassword';
import UserEmailVerify from './views/Pages/Login/UserEmailVerify';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Admin" component={Login} />
            <Route exact path="/forgot-password" name="Forgot Password" component={ForgotPassword} />
            <Route exact path="/account" name="Password Reset Page" component={ChangePassword} />
            <Route exact path="/user-account" name="Password Reset Page" component={UserChangePassword} />
            <Route exact path="/dboy-account" name="Password Reset Page" component={DriverChangePassword} />
            <Route exact path="/user-verify" name="Email Verify" component={UserEmailVerify} />
            <Route exact path="/lsp-register" name="Register LSP" component={LSPRegister} />
            <Route exact path="/lsp-forgot-password" name="Forgot Password" component={LSPForgotPassword} />
            <Route exact path="/lsp-account" name="Password Reset Page" component={LSPAccountChangePassword} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
