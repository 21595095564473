import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from "lodash";
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/admin_logo.png'
import sygnet from '../../assets/img/brand/admin_logo.png'
import PubNubReact from 'pubnub-react';
import connect from "react-redux/es/connect/connect";
import { adminLogin } from "../../actions/index";

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: 'pub-c-9fa67acd-2a4e-489e-b1b3-c1e267213b8c',
      subscribeKey: 'sub-c-b6a5e8de-c110-11ea-a44f-6e05387a1df4'
    });
    this.state = {
      unreadNoti: 0
    }
    this.pubnub.init(this);
    this.onLogoutHandle = this.onLogoutHandle.bind(this);
  }

  componentWillMount() {

    this.pubnub.subscribe({
      channels: [this.props.admin._id],
      withPresence: true
    });

    this.pubnub.getMessage(this.props.admin._id, (resP) => {
      const location = window.location.hash;
      if (!_.isEmpty(resP) && location !== '#/messages') {
        this.setState({ unreadNoti: (this.state.unreadNoti + 1) })
      }
      if (!_.isEmpty(resP) && location === '#/messages') {
        this.setState({ unreadNoti: 0 })
      }
    });
  }

  onLogoutHandle() {
    if (!window.confirm("Are you sure to logout?")) {
      return false;
    } else {
      localStorage.removeItem("admin");
      localStorage.setItem("redirect", (this.props.admin.account_type === 'admin') ? "/login" : '/lsp-login');
      this.props.adminLogin({});
    }
  }
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 120, alt: 'Speedy Tum Tum Logo' }}
          minimized={{ src: sygnet, width: 120, alt: 'Speedy Tum Tum Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="ml-auto bell-icon-custom" navbar>
          {this.props.admin && (this.props.admin.account_type === 'lsp') && <NavItem className="d-md-down-none">
            <Link to="/messages" color="light"><NavLink><i className="icon-bell"></i>{(this.state.unreadNoti > 0) && <Badge pill color="danger">{this.state.unreadNoti}</Badge>}</NavLink></Link>
          </NavItem>}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              {/*<img src={'assets/img/avatars/6.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />*/}
              <i className="fa fa-cog" aria-hidden="true"></i>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>{_.upperFirst(this.props.admin.username)}</strong></DropdownItem>
              <DropdownItem tag={Link} to={(this.props.admin.account_type === 'admin') ? "profile" : 'lsp-profile'}><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem tag={Link} to={"logout"} onClick={this.onLogoutHandle}><i className="fa fa-lock"></i>Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
