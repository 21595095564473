import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const LSPDashboard = Loadable({
  loader: () => import('./views/Dashboard/LSPDashboard'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/Profile/LSPProfile'),
  loading: Loading,
});

const LSPJobs = Loadable({
  loader: () => import('./views/Jobs/LSPJobs'),
  loading: Loading,
});

const Messages = Loadable({
  loader: () => import('./views/Messages'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const lsp_routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: 'LSP Dashboard', component: LSPDashboard },
  { path: '/lsp-profile', name: 'Profile', component: Profile },
  { path: '/requests-jobs', name: 'Requests & Jobs', component: LSPJobs },
  { path: '/messages', name: 'Messages', component: Messages }
];

export default lsp_routes;
