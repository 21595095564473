import apiPath from './apiPath';
import { isEmpty } from 'lodash';
import validator from 'validator';

var helper = {
  getBages: function (status) {
    return status === 'active' ? 'success' :
      status === 'inactive' ? 'warning' :
        status === 'deleted' ? 'danger' : 'primary'
  },
  serialize: function (obj, prefix) {
    var str = [];
    var p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ? this.serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  },
  getBase64: function (file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  },
  getDefaultCarImage: function (event) {
    event.target.src = `${apiPath.baseURL}img/v/default-no-car.png`
  },
  validatePassword: function (value) {
    if (!isEmpty(value)) {
      return validator.isLength(value, { min: 6 });
    } else {
      return true;
    }
  },
  mobileWithoutCodeView: function (number = '') {

    if (number) {
      return (number.indexOf('+1') === 0) ? number.replace('+1', '') : number;
    } else {
      return '';
    }

  },
  mobileWithCode: function (number = '') {
    if (number) {
      return number.indexOf('+1') === -1 ? `+1${number}` : `${number}`
    } else {
      return '';
    }
  },
  getLanguageFromCookie : function (name) {
    var c = document.cookie.split('; '),
    cookies = {}, i, j;
    for (i = c.length - 1; i >= 0; i--) {
      j = c[i].split('=');
      cookies[j[0]] = j[1];
    }
    return cookies[name];
  }
}
export default helper;
