export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Requests & My Jobs',
      url: '/requests-jobs',
      icon: 'fa fa-hand-paper-o',
    },
    {
      name: 'Messages',
      url: '/messages',
      icon: 'fa fa-wechat',
    }
  ],
};
