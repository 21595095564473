import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Alert, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import _ from "lodash";
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Http from '../../../services/Http';
import apiPath from '../../../constants/apiPath';
import { adminLogin } from "../../../actions/index";
import { connect } from "react-redux";
import queryString from 'query-string';

const mapStateToProps = state => {
  return { admin: state.admin };
};

const mapDispatchToProps = dispatch => {
  return {
    adminLogin: admin => dispatch(adminLogin(admin))
  };
};

class UserEmailVerify extends Component {
  constructor(props) {
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      loading: false,
      immediate: true,
      setFocusOnError: true,
      clearInputOnReset: false,
      password: '',
      text: '',
      token: queryString.parse(this.props.location.search).token
    }


  }

  responseError() {
    if (this.state.submitFormError) {
      return <Alert color="danger">
        {this.state.submitFormError}
      </Alert>
    }
  }

  responseSuccess() {
    if (this.state.submitFormSuccess) {
      return <Alert color="success">
        {this.state.submitFormSuccess}
      </Alert>
    }
  }

  showLoading() {
    if (this.state.loading) {
      return <i className="fa fa-spinner fa-spin"></i>
    }
  }

  async getEmailVerification() {
    let path = apiPath.getEmailVerification;
    this.setState({ loading: true }); this.setState({ documents: { docs: [] } })
    let options = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }
    let result = await Http(path + "?token=" + this.state.token, options);
    this.setState({ loading: false });
    if (result.ok) {
      if (result.status === 200) {
        let resultJson = await result.json();
        this.setState({ text: resultJson.text});
        if (resultJson.success) {
          //let resultJobsJson = resultJson.result || {};
          //console.log(resultJobsJson);
        } else {
          this.setState({ error: resultJson.msg });
          setTimeout(() => {
            this.setState({ error: null });
          }, 5000);
        }
      }
    } else {
      this.setState({ error: result.statusText });
      setTimeout(() => {
        this.setState({ error: null });
      }, 5000);
    }
  }

  componentDidMount() {
    this.getEmailVerification();
  }

  render() {
    return (
      <div className="app flex-row align-items-center login-body">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  {this.responseError()} {this.responseSuccess()}
                  <CardBody className="emailVerify">
                    <div>
                      <h2>{this.state.text}</h2>
                    </div>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2 className="notranslate">Speedy Tum Tum</h2>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserEmailVerify);
                       
/*if (_.isEmpty(this.state.token)) {
      return <Redirect to='/login' />
    }*/