// let appMode = 'development';
let appMode = 'production';
/* if(window.location.origin === 'http://localhost:4300' || window.location.origin === 'http://192.168.1.248:4300') {
    appMode = 'stage';
} */
//let URL = (appMode === 'production') ? 'http://14.98.110.245:4301/' : 'http://192.168.1.92:4301/';
let URL = (appMode === 'production') ? 'https://api.speedytumtum.com/' : 'http://localhost:4301/';
let pathObj = {
    baseURL: URL,
    dashboardData: 'api/admins/dashboardData',
    getActiveDrivers: 'api/admins/getActiveDrivers',
    LSPDashboardData: 'api/lsp/LSPDashboardData',
    updateAdminProfile: 'api/admins/updateAdminProfile',
    adminLogin: 'api/admins/login',
    registerLSP: 'api/lsp/registerLSP',
    LSPForgotPassword: 'api/lsp/LSPForgotPassword',
    LSPChangePassword: 'api/lsp/LSPChangePassword',
    userResetPassword: 'api/admins/userResetPassword',
    dboyResetPassword: 'api/admins/dboyResetPassword',
    getEmailVerification: 'api/admins/getEmailVerification',
    forgotPassword: 'api/admins/forgot-password',
    changePassword: 'api/admins/change-password',
    getLSPJobs: 'api/lsp/getLSPJobs',
    updateLSPProfile: 'api/lsp/updateLSPProfile',
    lspChatList: 'api/lsp/lspChatList',
    lspChatHistory: 'api/lsp/lspChatHistory',
    sendMsgByLsp: 'api/lsp/sendMsgByLsp',
    getUsers: 'api/admins/getUsers',
    getUser: 'api/admins/getUser',
    addUser: 'api/admins/addUser',
    updateUser: 'api/admins/updateUser',
    getUserOrders: 'api/admins/getUserOrders',
    getUserAddresses: 'api/admins/getUserAddresses',
    getDeliveryBoys: 'api/admins/getDeliveryBoys',
    getDeliveryBoy: 'api/admins/getDeliveryBoy',
    addDeliveryBoy: 'api/admins/addDeliveryBoy',
    updateDeliveryBoy: 'api/admins/updateDeliveryBoy',
    getAllCountries: 'api/admins/getAllCountries',
    getCountryByID: 'api/admins/getCountryByID',
    getRestaurants: 'api/admins/getRestaurants',
    addRestaurant: 'api/admins/addRestaurant',
    updateRestaurant: 'api/admins/updateRestaurant',
    addRestaurantsCSV: 'api/admins/addRestaurantsCSV',
    getBranches: 'api/admins/getBranches',
    addBranch: 'api/admins/addBranch',
    updateBranch: 'api/admins/updateBranch',
    getHolidays: 'api/admins/getHolidays',
    addHoliday: 'api/admins/addHoliday',
    updateHoliday: 'api/admins/updateHoliday',
    getAllRestaurants: 'api/admins/getAllRestaurants',
    updateRestaurantMenuPriceEditor: 'api/admins/updateRestaurantMenuPriceEditor',
    getRestaurantMenuCategories: 'api/admins/getRestaurantMenuCategories',
    getAllCuisines: 'api/admins/getAllCuisines',
    getAllMaterials: 'api/admins/getAllMaterials',
    getAllExtraMaterials: 'api/admins/getAllExtraMaterials',
    getAllBranches: 'api/admins/getAllBranches',
    getAllBeverages: 'api/admins/getAllBeverages',
    getAllSoftDrinks: 'api/admins/getAllSoftDrinks',
    getCuisines: 'api/admins/getCuisines',
    addCuisine: 'api/admins/addCuisine',
    updateCuisine: 'api/admins/updateCuisine',
    getMaterials: 'api/admins/getMaterials',
    addMaterial: 'api/admins/addMaterial',
    updateMaterial: 'api/admins/updateMaterial',
    getRestaurantMenus: 'api/admins/getRestaurantMenus',
    addRestaurantMenu: 'api/admins/addRestaurantMenu',
    updateRestaurantMenu: 'api/admins/updateRestaurantMenu',
    getCountries: 'api/admins/getCountries',
    addCountry: 'api/admins/addCountry',
    updateCountry: 'api/admins/updateCountry',
    getCategories: 'api/admins/getCategories',
    addCategory: 'api/admins/addCategory',
    updateCategory: 'api/admins/updateCategory',
    getServices: 'api/admins/getServices',
    addService: 'api/admins/addService',
    updateService: 'api/admins/updateService',
    getOrders: 'api/admins/getOrders',
    updateOrder: 'api/admins/updateOrder',
    getDeliveryBoysByOrder: 'api/admins/getDeliveryBoysByOrder',
    updateDeliveryBoyOnOrder: 'api/admins/updateDeliveryBoyOnOrder',

    assignDeliveryBoy: 'api/admins/assignDeliveryBoy',

    getOrderFoodItems: 'api/admins/getOrderFoodItems',
    getTableReservations: 'api/admins/getTableReservations',
    updateTableReservation: 'api/admins/updateTableReservation',
    getEarnings: 'api/admins/getEarnings',
    getInvoices: 'api/admins/getInvoices',
    getSettings: 'api/admins/getSettings',
    updateSettings: 'api/admins/updateSettings',
    getNotifications: 'api/admins/getNotifications',
    addNotification: 'api/admins/addNotification',
    getZones: 'api/admins/getZones',
    addZone: 'api/admins/addZone',
    updateZone: 'api/admins/updateZone',
    getComments: 'api/admins/getComments',
    deleteReviews: 'api/admins/deleteReviews',
    getOffers: 'api/admins/getOffers',
    addOffer: 'api/admins/addOffer',
    updateOffer: 'api/admins/updateOffer',
    getPromotions: 'api/admins/getPromotions',
    addPromotion: 'api/admins/addPromotion',
    updatePromotion: 'api/admins/updatePromotion',
    getAllZones: 'api/admins/getAllZones',
    getZonesDetails: 'api/admins/getZonesDetails',
    getZonesDetailsByDboy: 'api/admins/getZonesDetailsByDboy',
    getCuisinesForRestaurant: 'api/admins/getCuisinesForRestaurant',
    getCuisineDetailsForRestaurant: 'api/admins/getCuisineDetailsForRestaurant',



    getLsps: 'api/admins/getLsps',
    addLsp: 'api/admins/addLsp',
    addLspsCSV: 'api/admins/addLspsCSV',
    updateLsp: 'api/admins/updateLsp',
    addSubscription: 'api/admins/addSubscription',
    getSubscriptions: 'api/admins/getSubscriptions',
    getEmailTemplates: 'api/admins/getEmailTemplates',
    updateEmailTemplate: 'api/admins/updateEmailTemplate',
    updateSubscription: 'api/admins/updateSubscription',
    addVehicleMake: 'api/admins/addVehicleMake',
    getVehicleMakes: 'api/admins/getVehicleMakes',
    getAllVehicleMakes: 'api/admins/getAllVehicleMakes',
    updateVehicleMake: 'api/admins/updateVehicleMake',
    addVehicleModel: 'api/admins/addVehicleModel',
    getVehicleModels: 'api/admins/getVehicleModels',
    getAllVehicleModels: 'api/admins/getAllVehicleModels',
    getVehicles: 'api/admins/getVehicles',
    updateVehicle: 'api/admins/updateVehicle',
    updateVehicleModel: 'api/admins/updateVehicleModel',
    getJobs: 'api/admins/getJobs',
    updateJob: 'api/admins/updateJob',
    getTransactions: 'api/admins/getTransactions',
    getLoanRequests: 'api/admins/getLoanRequests',
    updateLoanRequest: 'api/admins/updateLoanRequest',
    updatePrivacyPolicy: 'api/admins/updatePrivacyPolicy',
    getContent: 'api/admins/getContent',
    updateCareers: 'api/admins/updateCareers',
    updateTerms: 'api/admins/updateTerms',

    updateListBusiness: 'api/admins/updateListBusiness',
    updateNews: 'api/admins/updateNews',
    
    getDeliveryBoyList: 'api/admins/getDeliveryBoyList',

    updateContactUs: 'api/admins/updateContactUs',
    updateAboutUs: 'api/admins/updateAboutUs',
    updateHelp: 'api/admins/updateHelp',
    updateUserAgreement: 'api/admins/updateUserAgreement',
    updateDeliveryReturnPolicy: 'api/admins/updateDeliveryReturnPolicy',
    updateFaq: 'api/admins/updateFaq',
    updateHowItWorks: 'api/admins/updateHowItWorks',
    updatePromotionTerms: 'api/admins/updatePromotionTerms',
    updateConfirmReceived: 'api/admins/updateConfirmReceived',

    getHoods: 'api/hoods/getHoods',
    addHood: 'api/hoods/addHood',
    updateHood: 'api/hoods/updateHood',

}
export default pathObj;
