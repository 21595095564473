import helper from './constants/helper';
var defLang = "";
defLang = helper.getLanguageFromCookie('googtrans');
console.log(defLang);
export default {
  items: [
    {
      name: (defLang == "/en/ru") ? "Главная панель" : ((defLang == "/en/tr") ? "Kontrol Paneli" : "Dashboard"),
      url: '/dashboard',
      icon: 'icon-speedometer',
      class: 'notranslate'
    },
    {
      name: 'Customers',
      url: '/users',
      icon: 'fa fa-users',
    },
    {
      name: 'Countries',
      url: '/countries',
      icon: 'fa fa-flag',
    },
    /*{
      name: 'Menu Categories ',
      url: '/categories',
      icon: 'fa fa-list-alt',
    },*/
    {
      name: 'Cuisines',
      url: '/cuisines',
      icon: 'fa fa-cutlery',
    },/*
    {
      name: 'Restaurants',
      url: '/restaurants',
      icon: 'fa fa-building',
    },*/
    {
      name: 'Restaurant Manager',
      url: '/restaurants/list',
      icon: 'fa fa-building'
    },
    {
      name: 'Restaurant Menus',
      url: '/menus/restaurant-menus',
      icon: 'fa fa-coffee',
    },
    {
      name: 'Menus price editor',
      url: '/menus/price-editor',
      icon: 'fa fa-edit',
    },
    {
      name: "Delivery Boys",
      url: '/delivery-boys',
      icon: 'fa fa-user',
      class: 'notranslate'
    },
    {
      name: "Orders",
      url: '/orders',
      icon: 'fa fa-shopping-cart',
      class: 'notranslate'
    },
    {
      name: 'Earnings',
      url: '/earnings',
      icon: 'fa fa-dollar'
    },
    {
      name: "Payment Settlement Invoices",
      url: '/invoices',
      icon: 'fa fa-file-pdf-o',
      class: 'notranslate'
    },
    {
      name: 'Offers',
      url: '/offers',
      icon: 'fa fa-bolt',
      children: [
        {
          name: 'Coupons Offers',
          url: '/offers/coupons',
          icon: 'fa fa-gift',
        },
        {
          name: 'Restaurant Offers',
          url: '/offers/restaurant',
          icon: 'fa fa-coffee',
        },
      ]
    },
    {
      name: 'Hoods',
      url: '/hoods',
      icon: 'fa fa-map'
    },
    {
      name: 'Reviews',
      url: '/reviews',
      icon: 'fa fa-comment'
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'fa fa-bell'
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'fa fa-cogs'
    },
    {
      name: 'Content Manager',
      url: '/contents',
      icon: 'fa fa-file-text',
      children: [
        {
          name: 'About Us',
          url: '/contents/about-us',
          icon: 'fa fa-plus',
        },
        {
          name: 'Privacy Policy',
          url: '/contents/privacy-policy',
          icon: 'fa fa-plus',
        },
        {
          name: 'Delivery Return Policy',
          url: '/contents/delivery-return-policy',
          icon: 'fa fa-plus',
        },
        {
          name: 'Terms and Conditions',
          url: '/contents/terms_and_conditions',
          icon: 'fa fa-plus',
        },
        {
          name: 'Help',
          url: '/contents/help',
          icon: 'fa fa-plus',
        },
        {
          name: 'Contact Us',
          url: '/contents/contact-us',
          icon: 'fa fa-plus',
        },
        {
          name: 'How It Works',
          url: '/contents/how-it-works',
          icon: 'fa fa-plus',
        },
        {
          name: 'FAQ',
          url: '/contents/faq',
          icon: 'fa fa-plus',
        },
        {
          name: 'List you business',
          url: '/contents/list-business',
          icon: 'fa fa-plus',
        },
        {
          name: 'Promotion Terms',
          url: '/contents/promotion-terms',
          icon: 'fa fa-plus',
        },
        {
          name: 'Latest News/Updates',
          url: '/contents/news',
          icon: 'fa fa-plus',
        },
      ]
    },
    {
      name: 'Email Templates',
      url: '/email-templates',
      icon: 'fa fa-envelope'
    }
  ],
};
