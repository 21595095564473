import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';
import helper from './constants/helper';

var defLang = "";
defLang = helper.getLanguageFromCookie('googtrans');
function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./views/Profile'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('./views/Users/Users'),
  loading: Loading,
});

const AddUser = Loadable({
  loader: () => import('./views/Users/AddUser'),
  loading: Loading,
});

const EditUser = Loadable({
  loader: () => import('./views/Users/EditUser'),
  loading: Loading,
});

const ViewUser = Loadable({
  loader: () => import('./views/Users/ViewUser'),
  loading: Loading,
});

const UserOrder = Loadable({
  loader: () => import('./views/Users/UserOrder'),
  loading: Loading,
});

const UserAddress = Loadable({
  loader: () => import('./views/Users/UserAddress'),
  loading: Loading,
});

const viewUserOrder = Loadable({
  loader: () => import('./views/Users/viewUserOrder'),
  loading: Loading,
});

const DeliveryBoys = Loadable({
  loader: () => import('./views/DeliveryBoys/DeliveryBoys'),
  loading: Loading,
});

const AddDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/AddDeliveryBoy'),
  loading: Loading,
});

const EditDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/EditDeliveryBoy'),
  loading: Loading,
});

const ViewDeliveryBoy = Loadable({
  loader: () => import('./views/DeliveryBoys/ViewDeliveryBoy'),
  loading: Loading,
});

const Restaurants = Loadable({
  loader: () => import('./views/Restaurants/Restaurants'),
  loading: Loading,
});

const AddRestaurant = Loadable({
  loader: () => import('./views/Restaurants/AddRestaurant'),
  loading: Loading,
});

const EditRestaurant = Loadable({
  loader: () => import('./views/Restaurants/EditRestaurant'),
  loading: Loading,
});

const ViewRestaurant = Loadable({
  loader: () => import('./views/Restaurants/ViewRestaurant'),
  loading: Loading,
});

const Branches = Loadable({
  loader: () => import('./views/Restaurants/Branches'),
  loading: Loading,
});

const AddBranch = Loadable({
  loader: () => import('./views/Restaurants/AddBranch'),
  loading: Loading,
});

const EditBranch = Loadable({
  loader: () => import('./views/Restaurants/EditBranch'),
  loading: Loading,
});

const ViewBranch = Loadable({
  loader: () => import('./views/Restaurants/ViewBranch'),
  loading: Loading,
});

const Holidays = Loadable({
  loader: () => import('./views/Restaurants/Holidays'),
  loading: Loading,
});

const AddHoliday = Loadable({
  loader: () => import('./views/Restaurants/AddHoliday'),
  loading: Loading,
});

const EditHoliday = Loadable({
  loader: () => import('./views/Restaurants/EditHoliday'),
  loading: Loading,
});

const ViewHoliday = Loadable({
  loader: () => import('./views/Restaurants/ViewHoliday'),
  loading: Loading,
});

const RestaurantMenus = Loadable({
  loader: () => import('./views/RestaurantMenus/RestaurantMenus'),
  loading: Loading,
});

const MenusPriceEditor = Loadable({
  loader: () => import('./views/RestaurantMenus/PriceEditor'),
  loading: Loading,
});


const AddRestaurantMenu = Loadable({
  loader: () => import('./views/RestaurantMenus/AddRestaurantMenu'),
  loading: Loading,
});

const EditRestaurantMenu = Loadable({
  loader: () => import('./views/RestaurantMenus/EditRestaurantMenu'),
  loading: Loading,
});

const Materials = Loadable({
  loader: () => import('./views/RestaurantMenus/Materials'),
  loading: Loading,
});

const AddMaterial = Loadable({
  loader: () => import('./views/RestaurantMenus/AddMaterial'),
  loading: Loading,
});

const EditMaterial = Loadable({
  loader: () => import('./views/RestaurantMenus/EditMaterial'),
  loading: Loading,
});

const Cuisines = Loadable({
  loader: () => import('./views/Cuisines/Cuisines'),
  loading: Loading,
});

const AddCuisine = Loadable({
  loader: () => import('./views/Cuisines/AddCuisine'),
  loading: Loading,
});

const EditCuisine = Loadable({
  loader: () => import('./views/Cuisines/EditCuisine'),
  loading: Loading,
});

const Zones = Loadable({
  loader: () => import('./views/Zones/Zones'),
  loading: Loading,
});

const AddZone = Loadable({
  loader: () => import('./views/Zones/AddZone'),
  loading: Loading,
});

const EditZone = Loadable({
  loader: () => import('./views/Zones/EditZone'),
  loading: Loading,
});

const Countries = Loadable({
  loader: () => import('./views/Countries/Countries'),
  loading: Loading,
});

const AddCountry = Loadable({
  loader: () => import('./views/Countries/AddCountry'),
  loading: Loading,
});

const EditCountry = Loadable({
  loader: () => import('./views/Countries/EditCountry'),
  loading: Loading,
});

const Categories = Loadable({
  loader: () => import('./views/Categories/Categories'),
  loading: Loading,
});

const AddCategory = Loadable({
  loader: () => import('./views/Categories/AddCategory'),
  loading: Loading,
});

const EditCategory = Loadable({
  loader: () => import('./views/Categories/EditCategory'),
  loading: Loading,
});

const Services = Loadable({
  loader: () => import('./views/Services/Services'),
  loading: Loading,
});

const AddService = Loadable({
  loader: () => import('./views/Services/AddService'),
  loading: Loading,
});

const EditService = Loadable({
  loader: () => import('./views/Services/EditService'),
  loading: Loading,
});

const ViewService = Loadable({
  loader: () => import('./views/Services/ViewService'),
  loading: Loading,
});

const Orders = Loadable({
  loader: () => import('./views/Orders/Orders'),
  loading: Loading,
});

const ViewOrder = Loadable({
  loader: () => import('./views/Orders/ViewOrder'),
  loading: Loading,
});

const Earnings = Loadable({
  loader: () => import('./views/Earnings/Earnings'),
  loading: Loading,
});

const Invoices = Loadable({
  loader: () => import('./views/Invoices/Invoices'),
  loading: Loading,
});

const Support = Loadable({
  loader: () => import('./views/Support/Support'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('./views/Settings/Settings'),
  loading: Loading,
});

const Notifications = Loadable({
  loader: () => import('./views/Notifications/Notifications'),
  loading: Loading,
});

const AddNotification = Loadable({
  loader: () => import('./views/Notifications/AddNotification'),
  loading: Loading,
});

const Reviews  = Loadable({
  loader: () => import('./views/Reviews/Reviews'),
  loading: Loading,
});

const CouponsOffers = Loadable({
  loader: () => import('./views/Offers/Coupons/Offers'),
  loading: Loading,
});

const CouponsAddOffer = Loadable({
  loader: () => import('./views/Offers/Coupons/AddOffer'),
  loading: Loading,
});

const CouponsEditOffer = Loadable({
  loader: () => import('./views/Offers/Coupons/EditOffer'),
  loading: Loading,
});

const CouponsViewOffer = Loadable({
  loader: () => import('./views/Offers/Coupons/ViewOffer'),
  loading: Loading,
});

const Promotions = Loadable({
  loader: () => import('./views/Promotions/Promotions'),
  loading: Loading,
});

const AddPromotion = Loadable({
  loader: () => import('./views/Promotions/AddPromotion'),
  loading: Loading,
});

const EditPromotion = Loadable({
  loader: () => import('./views/Promotions/EditPromotion'),
  loading: Loading,
});

const ViewPromotion = Loadable({
  loader: () => import('./views/Promotions/ViewPromotion'),
  loading: Loading,
});

const TransactionReports = Loadable({
  loader: () => import('./views/Reports/TransactionReports'),
  loading: Loading,
});

const PrivacyPolicy = Loadable({
  loader: () => import('./views/Contents/PrivacyPolicy'),
  loading: Loading,
});


const TermAndConditions = Loadable({
  loader: () => import('./views/Contents/TermAndConditions'),
  loading: Loading,
});

const AboutUs = Loadable({
  loader: () => import('./views/Contents/AboutUs'),
  loading: Loading,
});

const ContactUs = Loadable({
  loader: () => import('./views/Contents/ContactUs'),
  loading: Loading,
});

const Help = Loadable({
  loader: () => import('./views/Contents/Help'),
  loading: Loading,
});

const UserAgreement = Loadable({
  loader: () => import('./views/Contents/UserAgreement'),
  loading: Loading,
});

const DeliveryReturnPolicy = Loadable({
  loader: () => import('./views/Contents/DeliveryReturnPolicy'),
  loading: Loading,
});

const Faq = Loadable({
  loader: () => import('./views/Contents/Faq'),
  loading: Loading,
});

const ListBusiness = Loadable({
  loader: () => import('./views/Contents/ListBusiness'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('./views/Contents/News'),
  loading: Loading,
});

const HowItWorks = Loadable({
  loader: () => import('./views/Contents/HowItWorks'),
  loading: Loading,
});

const PromotionTerms = Loadable({
  loader: () => import('./views/Contents/PromotionTerms'),
  loading: Loading,
});

const EmailTemplates = Loadable({
  loader: () => import('./views/EmailTemplates/EmailTemplates'),
  loading: Loading,
});

const EditEmailTemplate = Loadable({
  loader: () => import('./views/EmailTemplates/EditEmailTemplate'),
  loading: Loading,
});


const Models = Loadable({
  loader: () => import('./views/Vehicles/Models'),
  loading: Loading,
});


const AddModel = Loadable({
  loader: () => import('./views/Vehicles/AddModel'),
  loading: Loading,
});

const EditModel = Loadable({
  loader: () => import('./views/Vehicles/EditModel'),
  loading: Loading,
});

const Vehicles = Loadable({
  loader: () => import('./views/Vehicles/Vehicles'),
  loading: Loading,
});

const EditVehicle = Loadable({
  loader: () => import('./views/Vehicles/EditVehicle'),
  loading: Loading,
});

const ViewVehicle = Loadable({
  loader: () => import('./views/Vehicles/ViewVehicle'),
  loading: Loading,
});

const Transactions = Loadable({
  loader: () => import('./views/Transactions/Transactions'),
  loading: Loading,
});


const Hoods = Loadable({
  loader: () => import('./views/Hoods/Hoods'),
  loading: Loading,
});

const AddHood = Loadable({
  loader: () => import('./views/Hoods/AddHood'),
  loading: Loading,
});

const EditHood = Loadable({
  loader: () => import('./views/Hoods/EditHood'),
  loading: Loading,
});

const Test = Loadable({
  loader: () => import('./views/Test/Test'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/dashboard', name: (defLang == "/en/ru") ? "Главная панель" : ((defLang == "/en/tr") ? "Kontrol Paneli" : "Dashboard"), component: Dashboard },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/users', exact: true, name: 'Customers', component: Users },
  { path: '/users/add', exact: true, name: 'Add Customer', component: AddUser },
  { path: '/users/edit', exact: true, name: 'Edit Customer', component: EditUser },
  { path: '/users/view', exact: true, name: 'View Customer', component: ViewUser },
  { path: '/users/orders', exact: true, name: 'View Customer Orders', component: UserOrder },
  { path: '/users/addresses', exact: true, name: 'View Customer Addresses', component: UserAddress },
  { path: '/users/orders-view', exact: true, name: 'Customer Order Detail', component: viewUserOrder },
  { path: '/delivery-boys', exact: true, name: (defLang == "/en/ru") ? "курьер" : ((defLang == "/en/tr") ? "Teslimatçı" : "Delivery Boys"), component: DeliveryBoys },
  { path: '/delivery-boys/add', exact: true, name: (defLang == "/en/ru") ? <span className="notranslate">курьер</span> : ((defLang == "/en/tr") ? <span className="notranslate">Teslimatçı ekle</span> : "Add Delivery Boy"), component: AddDeliveryBoy },
  { path: '/delivery-boys/edit', exact: true, name: 'Edit Delivery Boy', component: EditDeliveryBoy },
  { path: '/delivery-boys/view', exact: true, name: 'View Delivery Boy', component: ViewDeliveryBoy },
  { path: '/restaurants/list', exact: true, name: 'Restaurants', component: Restaurants },
  { path: '/restaurants/add', exact: true, name: 'Add Restaurant', component: AddRestaurant },
  { path: '/restaurants/edit', exact: true, name: 'Edit Restaurant', component: EditRestaurant },
  { path: '/restaurants/view', exact: true, name: 'View Restaurant', component: ViewRestaurant },
  { path: '/menus/restaurant-menus', exact: true, name: 'Items Manager', component: RestaurantMenus },
  { path: '/menus/price-editor', exact: true, name: 'Menu Price Editor', component: MenusPriceEditor },
  { path: '/menus/add-menu', exact: true, name: "Add Resturant Item", component: AddRestaurantMenu },
  { path: '/menus/edit-menu', exact: true, name: "Edit Restaurant Item", component: EditRestaurantMenu },
  { path: '/menus/materials', exact: true, name: "Materials Manager", component: Materials },
  { path: '/menus/add-material', exact: true, name: 'Add Material', component: AddMaterial },
  { path: '/menus/edit-material', exact: true, name: 'Edit Material', component: EditMaterial },
  { path: '/cuisines', exact: true, name: 'Cuisines', component: Cuisines },
  { path: '/cuisines/add', exact: true, name: 'Add Cuisine', component: AddCuisine },
  { path: '/cuisines/edit', exact: true, name: 'Edit Cuisine', component: EditCuisine },
  { path: '/countries', exact: true, name: 'Countries', component: Countries },
  { path: '/countries/add', exact: true, name: 'Add Country', component: AddCountry },
  { path: '/countries/edit', exact: true, name: 'Edit Country', component: EditCountry },
  { path: '/categories', exact: true, name: 'Menu Categories', component: Categories },
  { path: '/categories/add', exact: true, name: 'Add Menu Category', component: AddCategory },
  { path: '/categories/edit', exact: true, name: 'Edit Menu Category', component: EditCategory },
  { path: '/orders', exact: true, name: "Orders", component: Orders },
  { path: '/orders/view', exact: true, name: 'View Order', component: ViewOrder },
   { path: '/earnings', exact: true, name: 'Earnings', component: Earnings },
  { path: '/invoices', exact: true, name: 'Payment Settlement Invoices', component: Invoices },
 /* { path: '/support', exact: true, name: 'Support', component: Support },*/
  { path: '/settings', name: 'Settings', component: Settings },
  { path: '/notifications', exact: true, name: 'Notifications', component: Notifications },
  { path: '/notifications/add', exact: true, name: 'Send Notification', component: AddNotification },
  { path: '/reviews', exact: true, name: 'Reviews', component: Reviews },
  { path: '/offers/coupons', exact: true, name: 'Offers', component: CouponsOffers },
  { path: '/offers/coupons/add', exact: true, name: 'Add Offer', component: CouponsAddOffer },
  { path: '/offers/coupons/edit', exact: true, name: 'Edit Offer', component: CouponsEditOffer },
  { path: '/offers/coupons/view', exact: true, name: 'View Offer', component: CouponsViewOffer },
  { path: '/promotions', exact: true, name: 'Promotions', component: Promotions },
  { path: '/promotions/add', exact: true, name: 'Add Promotion', component: AddPromotion },
  { path: '/promotions/edit', exact: true, name: 'Edit Promotion', component: EditPromotion },
  { path: '/promotions/view', exact: true, name: 'View Promotion', component: ViewPromotion },


  { path: '/reports/transactions', exact: true, name: 'Transaction Reports', component: TransactionReports },

  { path: '/contents/privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy },
  { path: '/contents/contact-us', exact: true, name: 'Contact Us', component: ContactUs },
  { path: '/contents/about-us', exact: true, name: 'About Us', component: AboutUs },

  { path: '/contents/list-business', exact: true, name: 'List you business', component: ListBusiness },
  { path: '/contents/news', exact: true, name: 'Latest News/Updates', component: News },
  

  { path: '/contents/terms_and_conditions', exact: true, name: 'Term And Conditions', component: TermAndConditions },
  

  { path: '/contents/help', exact: true, name: 'Help', component: Help },
  { path: '/contents/user-agreement', exact: true, name: 'User Agreement', component: UserAgreement },
  { path: '/contents/delivery-return-policy', exact: true, name: 'Delivery Return Policy', component: DeliveryReturnPolicy },
  { path: '/contents/faq', exact: true, name: 'Faq', component: Faq },
  { path: '/contents/promotion-terms', exact: true, name: 'Promotion Terms', component: PromotionTerms },
  { path: '/contents/how-it-works', exact: true, name: 'How It Works', component: HowItWorks },
  { path: '/email-templates', exact: true, name: 'Email Templates', component: EmailTemplates },
  { path: '/email-templates/edit-email-template', exact: true, name: 'Edit Email Template', component: EditEmailTemplate },


  { path: '/vehicles/list', exact: true, name: 'Vehicles', component: Vehicles },
  { path: '/vehicles/list/edit-vehicle', exact: true, name: 'Edit Vehicle Details', component: EditVehicle },
  { path: '/vehicles/list/view-vehicle', exact: true, name: 'Vehicle Details', component: ViewVehicle },
  { path: '/transactions', exact: true, name: 'Transactions', component: Transactions },
  { path: '/test', exact: true, name: 'Email Templates', component: Test },

  { path: '/hoods', exact: true, name: 'Hoods', component: Hoods },
  { path: '/hoods/add', exact: true, name: 'Add Hood', component: AddHood },
  { path: '/hoods/edit', exact: true, name: 'Edit Hood', component: EditHood },
];

export default routes;
